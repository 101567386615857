export const titleKey: { [key: string]: string } = {
  gender: "Gender",
  age: "Age",
  relationship_status: "Relationship status",
  areas_of_life: "Areas of life",
  tools: "Tools",
  plans: "Plans",
};

// TODO add global
export const plansKey: { [key: string]: string } = {
  starter: "silver",
  basic: "gold",
  premium: "platinum",
};

export const valueKey: { [key: string]: string } = {
  starter: "silver",
  basic: "gold",
  premium: "platinum",
  female: "female",
  male: "male",
  unspecified: "non-binary",
  undisclosed: `don't identify with any gender`,
  in_a_relationship: "in a relationship",
  single: "single",
  married: "married",
  car_stuff: "car stuff",
  work: "work",
  finances: "finances",
  studies: "studies",
  health: "health",
  pets_stuff: "pets stuff",
  home_stuff: "home stuff",
  habits: "habits",
  kids_stuff: "kids stuff",
  payments: "payments",
  notes: "notes",
  checklists: "checklists",
  tasks: "tasks",
  events: "events",
  appointment: "appointment",
  reminders: "reminders",
  deadlines: "deadlines",
  meetings: "meetings",
  pets: "pets",
  home: "home",
  vehicles: "vehicles",
  kids: "kids",
  ai_tools: "AI tools",
  academic_growth: "Academic growth",
  business: "Business",
  family: "Family",
  financial_management: "Financial management",
  health_activity: "Health activity",
  organize_calendar: "Organize calendar",
  pet: "Pet",
  professional_progress: "Professional progress",
  property: "Property",
  vehicle: "Vehicle",
};
