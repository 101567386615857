import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import useSWR from "swr";
import moment from "moment";
import { useFetcher } from "../../hooks/useApi";
import { AnalyticTimePreset } from "../Analytics/analytic.enum";
import useCommon from "../../hooks/useCommon";
import { timeSelectConfig } from "../Analytics/analytic.config";
import { UsersType } from "./Users.type";
import UsersRow from "./UsersRow";
import { valueKey } from "./titleKeyObject.types";

const UsersList = () => {
  const fetcher = useFetcher();

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState<"desc" | "asc">("desc");
  const [sortBy, setSorBy] = useState("");
  const [startDay, setStartDay] = useState<any>("");
  const [datePreset, setDatePreset] = useState(AnalyticTimePreset.allTime);
  const [endDay, setEndDay] = useState<any>("");
  const { setBreadCrumbs } = useCommon();
  const theme = useTheme();

  const replaceUndefinedOrNull = (key: string, value: any) => {
    if (value === null || value === undefined || value === "") {
      return undefined;
    }

    return value;
  };

  useEffect(() => {
    setBreadCrumbs?.([{ label: "Users-List" }]);
  }, [setBreadCrumbs]);

  const searchParams = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          per_page: perPage,
          order_by: orderBy.toLocaleUpperCase(),
          page: page + 1,
          sort_by: sortBy,
          start_date_time: startDay
            ? moment(startDay)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : "",
          end_date_time: endDay
            ? moment(endDay).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            : "",
        },
        replaceUndefinedOrNull
      )
    );
  }, [perPage, orderBy, page, sortBy, startDay, endDay]);

  const searchParamsForDashboard = useCallback(() => {
    return JSON.parse(
      JSON.stringify(
        {
          start_date_time: startDay
            ? moment(startDay)
                .startOf("day")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss")
            : "",
          end_date_time: endDay
            ? moment(endDay).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
            : "",
        },
        replaceUndefinedOrNull
      )
    );
  }, [startDay, endDay]);

  const { data } = useSWR(["/v1/users", searchParams()], fetcher);
  const { data: dashboardInfo } = useSWR(
    ["/v1/users/dashboard", searchParamsForDashboard()],
    fetcher
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (property: string) => () => {
    const isAscending = sortBy === property && orderBy === "asc";
    setSorBy(property);
    setOrderBy(isAscending ? "desc" : "asc");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearSearchParams = () => {
    setStartDay("");
    setEndDay("");
    setDatePreset(AnalyticTimePreset.allTime);
  };

  useMemo(() => {
    if (datePreset === AnalyticTimePreset.allTime) {
      setEndDay("");
      setStartDay("");
    }
    if (datePreset === AnalyticTimePreset.today) {
      setStartDay(moment());
      setEndDay(moment());
    }
    if (datePreset === AnalyticTimePreset.yesterday) {
      setStartDay(moment().subtract(1, "day"));
      setEndDay(moment().subtract(1, "day"));
    }
    if (datePreset === AnalyticTimePreset.week) {
      setStartDay(moment().subtract(1, "week"));
      setEndDay(moment());
    }
    if (datePreset === AnalyticTimePreset.month) {
      setStartDay(moment().subtract(1, "month"));
      setEndDay(moment());
    }
  }, [datePreset]);

  const skeletonArray = Array(perPage).fill(Array(10).fill(""));

  const formattedDashboard = dashboardInfo
    ? {
        count_deactivated: dashboardInfo?.count_deactivated,
        count_registration: `${dashboardInfo?.count_registration || 0} / ${
          dashboardInfo?.count_activated || 0
        }`,
        count_undone_onboarding: dashboardInfo?.count_undone_onboarding,
        count_packages: dashboardInfo?.count_packages,
        count_canceled_packages: dashboardInfo?.count_canceled_packages,
        count_packages_web: `${dashboardInfo?.count_trial_web || 0} / ${
          dashboardInfo?.count_packages_web || 0
        }`,
        count_trial: dashboardInfo?.count_trial,
        count_packages_play_store: `${
          dashboardInfo?.count_trial_play_store || 0
        } / ${dashboardInfo?.count_packages_play_store || 0}`,
        count_packages_app_store: `${
          dashboardInfo?.count_trial_app_store || 0
        } / ${dashboardInfo?.count_packages_app_store || 0}`,
      }
    : null;

  return (
    <Box sx={{ width: "100%" }}>
      <Card>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "200px", marginLeft: "20px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date preset
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={datePreset}
                    label="Date preset"
                    onChange={(e) => {
                      setDatePreset(e.target.value as AnalyticTimePreset);
                    }}
                  >
                    {timeSelectConfig.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <TextField
                id="date"
                label="Start date"
                type="date"
                value={startDay ? moment(startDay).format("YYYY-MM-DD") : ""}
                variant="standard"
                onChange={(e) => setStartDay(e.target.value)}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="End date"
                variant="standard"
                type="date"
                onChange={(e) => setEndDay(e.target.value)}
                value={endDay ? moment(endDay).format("YYYY-MM-DD") : ""}
                sx={{ width: 150, marginLeft: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Fade in={!!startDay || !!endDay}>
                <Box sx={{ marginLeft: "20px" }}>
                  <IconButton onClick={clearSearchParams} size="small">
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Fade>
            </Box>
          </Box>
        </Toolbar>
        {formattedDashboard ? (
          <>
            <Box
              sx={{
                p: "20px 0 0 10px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                variant="subtitle1"
              >
                Counters
              </Typography>
            </Box>

            <Table
              sx={{
                border: `1px solid rgba(224, 224, 224, 1)`,
                borderRadius: "5px",
                marginBottom: "25px",
              }}
              size="small"
              aria-label="purchases"
            >
              <TableHead>
                <TableRow sx={{ background: theme.palette.action.hover }}>
                  {Object.keys(formattedDashboard).map(
                    (item: string, index: number) => (
                      <TableCell key={index}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          {valueKey[item]}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(formattedDashboard).map(
                    (value: any, index) => (
                      <TableCell key={index}>
                        <Typography variant="body1">{value}</Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <>
            <Box
              sx={{
                p: "20px 0 0 10px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                variant="subtitle1"
              >
                Counters
              </Typography>
            </Box>
            <Skeleton animation="wave" variant="text" height="100px" />
          </>
        )}

        <TableContainer>
          <Table
            aria-label="simple table"
            size="small"
            sx={{ border: `1px solid rgba(224, 224, 224, 1)` }}
          >
            <TableHead>
              <TableRow sx={{ background: theme.palette.action.hover }}>
                <TableCell align="center">
                  <TableSortLabel
                    sx={{
                      fontWeight: "bold",
                    }}
                    onClick={handleSort("id")}
                    active={sortBy === "id"}
                    direction={sortBy === "id" ? orderBy : "asc"}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("created_at")}
                    active={sortBy === "created_at"}
                    direction={sortBy === "created_at" ? orderBy : "asc"}
                  >
                    Reg Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Full name</TableCell>
                <TableCell size="small">Email</TableCell>

                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("phone")}
                    active={sortBy === "phone"}
                    direction={sortBy === "phone" ? orderBy : "asc"}
                  >
                    Phone
                  </TableSortLabel>{" "}
                </TableCell>
                <TableCell>Last step</TableCell>

                <TableCell>Package</TableCell>
                <TableCell>
                  <TableSortLabel
                    onClick={handleSort("canceled_at")}
                    active={sortBy === "canceled_at"}
                    direction={sortBy === "canceled_at" ? orderBy : "asc"}
                  >
                    Canceled at
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left">
                  <TableSortLabel
                    onClick={handleSort("deleted_at")}
                    active={sortBy === "deleted_at"}
                    direction={sortBy === "deleted_at" ? orderBy : "asc"}
                  >
                    Deleted at
                  </TableSortLabel>
                </TableCell>
                <TableCell size="small" align="left" />
              </TableRow>
            </TableHead>
            {data ? (
              <TableBody>
                {data.data.map((item: UsersType) => (
                  <UsersRow key={item.id} data={item} />
                ))}
              </TableBody>
            ) : (
              skeletonArray.map((item, index: number) => (
                <TableRow key={index}>
                  {item.map((el: string[], ind: number) => (
                    <TableCell key={ind} component="th" scope="row">
                      <Skeleton animation="wave" variant="text" height="30px" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};
export default UsersList;
