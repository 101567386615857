import React, { FC } from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { GlobalOnboardingData } from "../../OnboardingData.types";
import { valueKey, titleKey } from "../../titleKeyObject.types";

type Props = {
  data: GlobalOnboardingData | null;
};

const OnboardingGeneralData: FC<Props> = ({ data }) => {
  const theme = useTheme();

  const skeletonArray = Array(12).fill(Array(1).fill(""));

  return (
    <Box>
      {data
        ? Object.keys(data).map((item, key) => (
            <>
              <Box
                key={key}
                sx={{
                  p: "20px 0 0 10px",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                  variant="subtitle1"
                >
                  {titleKey[item]}
                </Typography>
              </Box>

              <Table
                sx={{
                  border: `1px solid rgba(224, 224, 224, 1)`,
                  borderRadius: "5px",
                }}
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow sx={{ background: theme.palette.action.hover }}>
                    {Object.keys(data[item]).map(
                      (items: string, index: number) => (
                        <TableCell key={index}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                            }}
                            variant="body1"
                          >
                            {item === "age" ? items : valueKey[items]}
                          </Typography>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.values(data[item]).map(
                      (value: number, index: number) => (
                        <TableCell key={index}>
                          <Typography variant="body1">{value}</Typography>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </>
          ))
        : skeletonArray.map((item, index: number) => (
            <Table>
              <TableRow key={index}>
                {item.map((el: string[], ind: number) => (
                  <TableCell key={ind} component="th" scope="row">
                    <Skeleton animation="wave" variant="text" height="60px" />
                  </TableCell>
                ))}
              </TableRow>
            </Table>
          ))}
    </Box>
  );
};

export default OnboardingGeneralData;
