import React, { FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Table,
  TableContainer,
  useTheme,
} from "@mui/material";
import { useApi } from "../../hooks/useApi";
import useCommon from "../../hooks/useCommon";
import AnalyticsFilters from "../Analytics/components/filters";
import { AnalyticsFiltersForm } from "../Analytics/analitytics.types";
import { AnalyticTimePreset } from "../Analytics/analytic.enum";
import OnboardingUserData from "./components/OnboardingUserData";
import OnboardingGeneralData from "./components/OnboardingGeneralData";
import { GlobalOnboardingData } from "./OnboardingData.types";
import { getAnalyticsData } from "./functions/getAnalyticsData.types";

type Props = {
  id?: number;
};

const OnboardingData: FC<Props> = ({ id }) => {
  const api = useApi();
  const { setBreadCrumbs } = useCommon();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useState<AnalyticsFiltersForm>({
    search: "",
    dateFrom: null,
    dateTo: null,
    preset: AnalyticTimePreset.allTime,
  });

  const [data, setData] = useState<GlobalOnboardingData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAnalyticsData(setData, setLoading, searchParams, api, id);
  }, [searchParams]);

  useEffect(() => {
    if (!id) {
      setBreadCrumbs?.([{ label: "Onboarding data" }]);
    }
  }, [setBreadCrumbs]);

  return (
    <Box sx={{ width: "97%" }}>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: () => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {data && !!id ? (
        <OnboardingUserData data={data} />
      ) : (
        <Card>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <Box sx={{ m: "20px 24px" }}>
                <AnalyticsFilters
                  isSearch
                  handleSearch={(form) => {
                    setSearchParams(form);
                  }}
                />
              </Box>
              <Box>
                <OnboardingGeneralData data={data} />
              </Box>
            </Table>
          </TableContainer>
        </Card>
      )}
    </Box>
  );
};

export default OnboardingData;
