import { FC, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import useSWR from "swr";
import AnalyticsFilters from "../filters";
import { useFetcher } from "../../../../hooks/useApi";
import { AnalyticTimePreset } from "../../analytic.enum";
import { AnalyticsFiltersForm } from "../../analitytics.types";
import AnalyticTable from "../AnalyticTable";
import {
  GlobalAnalyticHeaderContainer,
  GlobalAnalyticHeaderTitleContainer,
} from "./GlobalAnalytic.style";
import { GlobalAnalyticProps } from "./types";

const GlobalAnalytic: FC<GlobalAnalyticProps> = ({
  id,
  title = "Global Analytic",
  isSearch = false,
}) => {
  const fetcher = useFetcher();

  const [searchParams, setSearchParams] = useState<AnalyticsFiltersForm>({
    search: "",
    dateFrom: null,
    dateTo: null,
    preset: AnalyticTimePreset.allTime,
  });

  const searchParamsString = useMemo(() => {
    return `${
      searchParams.dateFrom
        ? `date_time_from=${searchParams.dateFrom} 00:00:00&`
        : ""
    }${
      searchParams.dateTo ? `date_time_to=${searchParams.dateTo} 23:59:59&` : ""
    }${searchParams.search ? `search=${searchParams.search}&` : ""}${
      id ? `user_id=${id}` : ""
    }`;
  }, [searchParams]);

  const { data: totalEarnings } = useSWR(
    ["user-info/total-earnings", `?${searchParamsString}`],
    fetcher
  );
  const { data: completedRenewal } = useSWR(
    ["user-info/completed-renewal", `?${searchParamsString}`],
    fetcher
  );

  const { data: onboardingInfo } = useSWR(
    ["/user-info/onboarding", `?${searchParamsString}`],
    fetcher
  );

  const { data: connectedServicesInfo } = useSWR(
    ["/user-info/connected-services", `?${searchParamsString}`],
    fetcher
  );

  const formattedDashboard =
    totalEarnings && completedRenewal
      ? {
          canceled_accounts: totalEarnings?.canceled_accounts || 0,
          expected: totalEarnings?.expected ? totalEarnings.expected / 100 : 0,
          total: completedRenewal?.total ? completedRenewal.total / 100 : 0,
        }
      : null;

  return (
    <>
      <GlobalAnalyticHeaderContainer>
        <GlobalAnalyticHeaderTitleContainer>
          <Typography variant="h5">{title}</Typography>
        </GlobalAnalyticHeaderTitleContainer>
        <Box>
          <AnalyticsFilters
            isSearch={isSearch}
            handleSearch={(form) => setSearchParams(form)}
          />
        </Box>
      </GlobalAnalyticHeaderContainer>

      <AnalyticTable data={formattedDashboard} />

      <AnalyticTable data={onboardingInfo?.registration_tools} />

      <AnalyticTable data={connectedServicesInfo} />
    </>
  );
};

export default GlobalAnalytic;
