import { FC } from "react";

import {
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { valueKey } from "../GlobalAnalytics/titleKeyObject.types";
import { AnalyticTableProps } from "./types";
import { AnalyticTableContainer } from "./AnalyticTable.style";

const AnalyticTable: FC<AnalyticTableProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <>
      {data ? (
        <AnalyticTableContainer size="small" aria-label="purchases">
          <TableHead>
            <TableRow sx={{ background: theme.palette.action.hover }}>
              {Object.keys(data).map((item: string, index: number) => (
                <TableCell key={index}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="body1"
                  >
                    {valueKey[item]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.values(data).map((value, index) => (
                <TableCell key={index}>
                  <Typography variant="body1">{value}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </AnalyticTableContainer>
      ) : (
        <Skeleton animation="wave" variant="text" height="100px" />
      )}
    </>
  );
};

export default AnalyticTable;
