export const valueKey: { [key: string]: string } = {
  canceled_accounts: "Canceled accounts",
  expected: "Expected sum",
  total: "Total sum",
  academic_growth: "Academic growth",
  financial_management: "Financial management",
  health_activity: "Health activity",
  organize_calendar: "Organize calendar",
  pet: "Pet",
  professional_progress: "Professional progress",
  property: "Property",
  vehicle: "Vehicle",
  family: "Family",
  business: "Business",

  credit_score_count_connected: "Credit score count connected",
  finance_count_connected: "Finance count connected",
  finance_count_connected_on_registration_step:
    "Finance count connected on registration step",
  google_sync_count_connected: "Google sync count connected",
  google_sync_count_connected_on_registration_step:
    "Google sync count connected on registration step",
};
